<template>
  <div v-if="hero" class="hero-page page">
    <div class="hero-page__header">
      <div class="container-padding">
        <div class="hero-page__inner">
          <PageBackComponent />
          <div class="hero-page__content">
            <h2 v-if="hero.title">{{ hero.title }}</h2>
            <HeroInfoComponent :hero="hero" />
          </div>
        </div>
      </div>
    </div>
    <div class="hero-page__bottom">
      <div class="container-padding">
        <div class="hero-page__content">
          <SliderComponent
            v-if="hero.image_sliders && hero.image_sliders.length"
            :items="hero.image_sliders"
            :slider-options="imageSliderOptions"
            v-slot="{ item }"
            class="hero-page__sliders"
          >
            <ImgComponent :img="item.img" class="hero-page__sliders-img" />
          </SliderComponent>
          <SliderComponent
            v-if="hero.video_sliders && hero.video_sliders.length"
            :items="hero.video_sliders"
            :slider-options="videoSliderOptions"
            v-slot="{ item }"
            class="hero-page__sliders"
          >
            <iframe
              :src="item.video_url"
              width="1000"
              height="500"
              allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
              frameborder="0"
              allowfullscreen
              class="hero-page__sliders-video"
            />
          </SliderComponent>
          <HeroAboutComponent :hero="hero" />
          <HeroOtherComponent :heroes="heroes" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageBackComponent from "components/PageBackComponent.vue";
import HeroAboutComponent from "../components/HeroAboutComponent.vue";
import HeroInfoComponent from "../components/HeroInfoComponent.vue";
import HeroOtherComponent from "../components/HeroOtherComponent.vue";
import HERO_OPEN_PAGE from "@/graphql/pages/hero_open_page.graphql";
import SliderComponent from "components/SliderComponent.vue";
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "HeroOpenPage",
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        query: HERO_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
          first: parseInt(4),
        },
      })
      .then(({ data }) => {
        store.state.heroes_open_page.hero = data.heroes_item;
        store.state.heroes_open_page.heroes = data.heroes_paginate;
      });
  },
  computed: {
    imageSliderOptions() {
      return {
        slidesPerView: 1.5,
        spaceBetween: 15,
        breakpoints: {
          500: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2.5,
          },
        },
      };
    },
    videoSliderOptions() {
      return {
        slidesPerView: "auto",
      };
    },
    hero() {
      return this.$store.state.heroes_open_page.hero;
    },
    heroes() {
      return this.$store.state.heroes_open_page.heroes.data;
    },
  },
  metaInfo() {
    const meta = this.$store.state.heroes_open_page.hero;
    return {
      title: meta?.title,
    };
  },
  components: {
    ImgComponent,
    SliderComponent,
    HeroOtherComponent,
    HeroInfoComponent,
    HeroAboutComponent,
    PageBackComponent,
  },
};
</script>

<style lang="stylus">
.hero-page {

  &__header {
    padding 126px 0 50px 0
    background var(--gray-light)
    +below(965px) {
      padding 150px 0 30px 0
    }
  }

  &__bottom {
    padding 50px 0 0 0
  }

  &__inner {
    display flex
    flex-direction column
    gap 30px
  }

  &__content {
    display flex
    flex-direction column
    gap 50px
  }

  &__sliders-img {
    width 100%
    max-width 500px
    height 100%
    max-height 314px
    border-radius 15px
  }

  &__sliders-video {
    border-radius 15px
  }
}
</style>
