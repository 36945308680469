<template>
  <div v-if="hero" class="hero-info">
    <ImgComponent v-if="hero.img" :img="hero.img" class="hero-info__image" />
    <div class="hero-info__content">
      <div v-if="hero.achievement" class="hero-info__achievement">
        <IconComponent class="hero-info__achievement-icon" name="medal" />
        <span class="hero-info__achievement-title">{{ hero.achievement }}</span>
      </div>
      <div v-if="hero.rank" class="hero-info__rank">{{ hero.rank }}</div>
      <div v-if="hero.description" class="hero-info__biography">
        <span class="hero-info__biography-title">Справка</span>
        <EditorJSComponent :text="JSON.parse(hero.description)" />
      </div>
    </div>
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
import IconComponent from "components/IconComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "HeroInfoComponent",
  props: {
    hero: {
      type: Object,
      default: () => {},
    },
  },
  components: { EditorJSComponent, ImgComponent, IconComponent },
};
</script>

<style lang="stylus">
.hero-info {
  display flex
  gap 50px
  +below(1300px) {
    gap 30px
  }
  +below(1200px) {
    flex-direction column
  }

  &__content {
    max-width 818px
    display flex
    flex-direction column
    gap 15px
  }

  &__image {
    width 100%
    max-width 384px
    height 430px
    max-height 430px
    border-radius var(--main-radius)
  }

  &__achievement {
    background var(--white)
    display flex
    align-items center
    gap 10px
    padding 15px
    border-radius var(--small-radius)
  }

  &__achievement-icon {
    max-width 40px
    max-height 40px
  }


  &__achievement-title {
    font-size 1.125em
    font-weight 700
    line-height 28px
  }

  &__rank {
    background var(--white)
    padding 15px
    border-radius var(--small-radius)
  }

  &__biography {
    display flex
    flex-direction column
    gap 15px
  }

  &__biography-title {
    font-size 1.125em
    font-weight 700
    line-height 28px
  }
}
</style>
