<template>
  <div class="hero-other">
    <h2>Другие герои</h2>
    <div class="hero-other__list">
      <HeroCardComponent v-for="(hero, i) in heroes" :key="i" :hero="hero" />
    </div>
  </div>
</template>

<script>
import HeroCardComponent from "components/HeroCardComponent.vue";
export default {
  name: "HeroOtherComponent",
  props: {
    heroes: {
      type: Array,
      default: () => [],
    },
  },
  components: { HeroCardComponent },
};
</script>

<style lang="stylus">
.hero-other {
  display flex
  flex-direction column
  gap 50px

  &__list {
    display flex
    flex-wrap wrap
    gap 50px
    //+below(428px) {
    //  flex-direction column
    //}
  }
}
</style>
