<template>
  <div v-if="items && items.length" class="slider" :class="{ 'slider--grid': isGridMode }">
    <!--    <div v-if="title" class="slider__header">-->
    <!--      <h3 class="slider__title">{{ title }}</h3>-->
    <!--      <router-link :to="route" class="btn btn&#45;&#45;gray btn&#45;&#45;sm" v-if="buttonTitle">-->
    <!--        <span>{{ buttonTitle }}</span>-->
    <!--        <RemixIconComponent category="system" name="arrow-right-line" />-->
    <!--      </router-link>-->
    <!--    </div>-->
    <div class="slider__items">
      <div class="swiper" ref="items">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, i) in items" :key="i">
            <slot :item="item"></slot>
          </div>
        </div>
      </div>
      <SliderArrowsComponent
        v-if="swiper && !hideArrows && canShowArrows"
        :isBeginning="swiper.isBeginning"
        :isEnd="swiper.isEnd"
        @left="swiper.slidePrev()"
        @right="swiper.slideNext()"
      />
    </div>
  </div>
</template>

<script>
// import RemixIconComponent from "components/RemixIconComponent.vue";
import SliderArrowsComponent from "components/SliderArrowsComponent.vue";

export default {
  name: "SliderComponent",
  emits: ["slideChange"],
  props: {
    title: String,
    buttonTitle: String,
    items: Array,
    hideArrows: Boolean,
    route: {
      type: Object,
      default() {
        return {
          name: "",
        };
      },
    },
    sliderOptions: {
      type: Object,
      default() {
        return {
          slidesPerView: "auto",
          spaceBetween: 24,
          pagination: false,
          clickable: false,
        };
      },
    },
  },
  data() {
    return {
      swiper: null,
    };
  },
  computed: {
    canShowArrows() {
      let slidesPerView = this.getCurrentBreakpoint().slidesPerView;
      if (typeof slidesPerView === "string") {
        return slidesPerView === "auto";
      }
      if (typeof slidesPerView === "number") {
        return this.items.length > slidesPerView;
      }
      return false;
    },
    isGridMode() {
      return this.sliderOptions?.grid;
    },
  },
  mounted() {
    this.initializeSwiper();
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  },
  methods: {
    initializeSwiper() {
      require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
        this.swiper = new Swiper(this.$refs.items, this.sliderOptions);
        if (this.sliderOptions.isActive) {
          this.swiper.slideTo(this.sliderOptions.isActive);
        }

        this.swiper.on("slideChange", () => {
          this.$emit("slideChange", this.swiper);
        });
      });
    },
    updateSwiper() {
      if (this.swiper) {
        this.swiper.update(); // Обновляем слайдер
      }
    },
    getCurrentBreakpoint() {
      if (this.sliderOptions.breakpoints && typeof window !== "undefined") {
        let returnCandidate = this.sliderOptions;
        Object.keys(this.sliderOptions.breakpoints).forEach((key) => {
          if (window.innerWidth > parseInt(key)) {
            returnCandidate = this.sliderOptions.breakpoints[key];
          }
        });
        return returnCandidate;
      }
      return this.sliderOptions;
    },
  },
  watch: {
    items() {
      this.$nextTick(() => {
        this.updateSwiper(); // Вызываем метод обновления слайдера после изменения массива items
      });
    },
  },
  components: {
    SliderArrowsComponent,
    // RemixIconComponent,
  },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css"

.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  width 100%
  display flex
  text-align: center;
  font-size: 18px;
  //background: #fff;
  //height: auto
  justify-content: center;
  align-items: center;
}

.slider {
  display flex
  flex-direction: column;
  justify-content center
  align-items center
  //padding 0 15px
  height: 100%;

  &--grid {
    .swiper-slide {
      height: calc((100% - 24px) / 2) !important;
    }
  }

  &__header {
    max-width var(--main-width)
    display flex
    align-items center
    width 100%
    margin-bottom 34px
    justify-content space-between
    +below(860px) {
      display grid
      grid-gap 16px
      justify-content stretch

      .btn {
        width 100%
        justify-content center
      }
    }
    +below(480px) {
      margin-bottom: 16px
      gap: 10px
    }
  }

  &__title {
    font-weight: 500;
    font-size: 2em;
    line-height: 32px;
    margin 0
    +below(480px) {
      font-weight: 700;
      font-size: 1.375em
      line-height: 30px;
    }
  }

  &__items {
    position relative
    max-width var(--main-width)
    width 100%
    height 100%
  }
}
</style>
