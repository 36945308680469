<template>
  <a @click="$router.go(-1)" class="page-back">
    <IconComponent class="page-back__icon" name="arrow-left-line" />
    <span>Вернуться назад</span>
  </a>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "PageBackComponent",
  components: { IconComponent },
};
</script>

<style lang="stylus">
.page-back {
  display flex
  align-items center
  gap 10px
  color var(--gray)
  max-width 194px

  &__icon {
    max-width 50px
    max-height 7px

    svg path {
      fill var(--gray)
    }
  }
}
</style>
