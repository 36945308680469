<template>
  <div class="slider-arrows">
    <button
      type="button"
      title="Влево"
      class="slider-arrows__item slider-arrows__item--left"
      @click="$emit('left')"
      v-show="showPrev"
    >
      <IconComponent name="arrow-left-line" />
    </button>
    <button
      type="button"
      title="Вправо"
      class="slider-arrows__item slider-arrows__item--right"
      @click="$emit('right')"
      v-show="showNext"
    >
      <IconComponent name="arrow-right-line" />
    </button>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "SliderArrowsComponent",
  props: {
    swiper: Object,
    isEnd: Boolean,
    isBeginning: Boolean,
  },
  computed: {
    showPrev() {
      return !this.isBeginning || this.swiper?.passedParams.loop;
    },
    showNext() {
      return !this.isEnd || this.swiper?.passedParams.loop;
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.slider-arrows {
  z-index: 1;

  &__item {
    z-index: 1;
    margin auto
    background: var(--white);
    border: 1px solid var(--gray-2)
    box-shadow: 0 2px 6px 0 var(--black-o1)
    box-sizing: border-box;
    border-radius: 40px;
    width 48px
    height 48px
    display flex
    align-items center
    justify-content center
    cursor pointer
    transition var(--transition)

    &:not([disabled]):hover {
      border-color var(--gray-dark)
    }

    .icon {
      width 24px
      height 24px

      svg path {
        transition var(--transition)
        fill var(--gray-dark)
      }
    }

    &--left {
      position absolute
      //left -19px
      left -26px
      top 0
      bottom 0
      margin-left 0
      margin-right auto
      +below(1360px) {
        left: -19px
      }
    }

    &--right {
      position absolute
      //right -19px
      right -26px
      top 0
      bottom 0
      margin-left auto
      margin-right 0
      +below(1360px) {
        right -19px
      }
    }
  }
}
</style>
