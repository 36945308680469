<template>
  <div v-if="hero" class="hero-about">
    <div v-if="hero.images && hero.images.length" class="hero-about__left">
      <ImgComponent v-for="(image, i) in hero.images" :key="i" :img="image.img" class="hero-about__image" />
    </div>
    <div v-if="hero.about" class="hero-about__right">
      <h3 class="hero-about__title">О Герое</h3>
      <EditorJSComponent :text="JSON.parse(hero.about)" />
    </div>
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "HeroAboutComponent",
  props: {
    hero: {
      type: Object,
      default: () => {},
    },
  },
  components: { EditorJSComponent, ImgComponent },
};
</script>

<style lang="stylus">
.hero-about {
  display flex
  gap 50px
  +below(1300px) {
    gap 30px
  }
  +below(1200px) {
    flex-direction column
  }

  &__left {
    display grid
    grid-template-columns repeat(2, 1fr)
    grid-gap 50px
    height fit-content
    width fit-content
    +below(650px) {
      grid-gap 15px
    }
  }

  &__right {
    max-width 601px
    display flex
    flex-direction column
    gap 30px
  }

  &__image {
    width 100%
    max-width 275px
    height 210px
    max-height 210px
    border-radius var(--main-radius)
    +below(650px) {
      max-width 176px
      height 144px
      max-height 144px
    }
  }

  &__title {
    font-size 2.125em
    font-weight 700
    line-height 41px
  }
}
</style>
